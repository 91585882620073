import React from "react"
import { Link } from "gatsby"

import {
  TiSocialFacebook,
  TiSocialTwitter,
  TiSocialPinterest,
  TiSocialLinkedin,
} from "react-icons/ti"
import { IoIosArrowRoundForward } from "react-icons/io"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Text from "reusecore/Text"
import Heading from "reusecore/Heading"
import Image from "reusecore/Image"
import { List, ListItem } from "reusecore/List"
import Button from "reusecore/Button"
import PageHeader from "../../components/PageHeader"
import Sidebar from "../../components/Omnize-Blog-sidebar"

import BlogThumb from "assets/images/omnize-images/Mask Group 83.png"
import authorImage from "assets/images/omnize-images/Rectangle 4048.png"

import AuthorThumb2 from "assets/images/testimonial/testimonial-thumb-2.jpg"
import AuthorThumb3 from "assets/images/testimonial/testimonial-thumb-3.jpg"
import AuthorThumb4 from "assets/images/testimonial/testimonial-thumb-4.jpg"
import AuthorThumb5 from "assets/images/testimonial/testimonial-thumb-5.jpg"

import bannerImage from "assets/images/omnize-images/Group 1755.png"
import bannerSquare from "assets/images/omnize-images/Group 8.svg"
import bannerCircle1 from "assets/images/omnize-images/Ellipse 1.svg"
import bannerCircle2 from "assets/images/omnize-images/Ellipse 2.svg"

import BlogPageWrapper from "./blogSingle.style"

const BlogSinglePage = () => {
  return (
    <BlogPageWrapper>
      <div className="blog-Container">
        <Container>
          <img
            className="banner__square"
            alt="cryptik banner thumb"
            src={bannerSquare}
          />{" "}
          <img
            className="banner__circle2"
            alt="cryptik banner thumb"
            src={bannerCircle2}
          />{" "}
          <img
            className="banner__circle1"
            alt="cryptik banner thumb"
            src={bannerCircle1}
          />{" "}
          <div className="blog-banner">
            <img
              className="banner__thumb"
              alt="cryptik banner thumb"
              src={bannerImage}
            />{" "}
          </div>{" "}
        </Container>{" "}
      </div>{" "}
      <Box className="single-post-wrapper">
        <Container>
          <Row>
            <Col className="xs-12 lg-8">
              <div className="single-blog-header">
                <Text className="blog-Text">
                  {" "}
                  5 SECRETS ABOUT BSUINESS GROWTH{" "}
                </Text>{" "}
              </div>{" "}
              <Row>
                <Col className="xs-12">
                  <Box className="single-post-block">
                    <Box className="post-thumb-block newblock">
                      <Image src={BlogThumb} alt="cryptik blog page" />
                      <Box className="post-content-block">
                        <Box className="post-meta-block">
                          <Text as="span"> Posted January 02, 2019 </Text>{" "}
                          {/*
                                    <Link to="/blog-single"> Discussion </Link>{" "}
                                    */}{" "}
                          <Text as="span" className="Boxider">
                            {" "}
                          </Text>{" "}
                          <Text as="span"> by Carl James </Text>{" "}
                          <Text as="span" className="Boxider">
                            {" "}
                          </Text>{" "}
                          <Text as="span"> 1507 views </Text>{" "}
                          <Text as="span" className="Boxider">
                            {" "}
                          </Text>{" "}
                          <Text as="span"> 2 comments </Text>{" "}
                          <Text as="span" className="Boxider">
                            {" "}
                          </Text>{" "}
                          <Text as="span"> 20 likes </Text>{" "}
                        </Box>{" "}
                        {/*
                                  <Text className="post-entry"> {post.body} </Text>{" "}
                                  <Link to="/blog-single" className="readmore-btn">
                                    see more <IoIosArrowRoundForward />
                                  </Link>{" "}
                                */}{" "}
                      </Box>{" "}
                    </Box>{" "}
                    <Box className="post-content-block text-block">
                      <Text className="entry-one">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua.At vero
                        eos et accusam et justo duo dolores et ea rebum.Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet.Lorem ipsum dolor sit amet,
                        consetetur sadipscing elitr, sed diam nonumy eirmod
                        tempor invidunt ut labore et dolore magna aliquyam erat,
                        sed diam voluptua.At vero eos et accusam et justo duo
                        dolores et ea rebum.Stet clita kasd gubergren, no sea
                        takimata sanctus est Lorem ipsum dolor sit amet.Lorem
                        ipsum dolor sit amet, consetetur sadipscing elitr, sed
                        diam nonumy eirmod tempor invidunt ut labore et dolore
                        magna aliquyam erat, sed diam voluptua.At vero eos et
                        accusam et justo duo dolores et ea rebum.{" "}
                      </Text>{" "}
                      <Text className="entry-two">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua.At vero
                        eos et accusam et justo duo dolores et ea rebum.Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet.{" "}
                      </Text>{" "}
                      <Text className="entry-four">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore{" "}
                      </Text>{" "}
                    </Box>{" "}
                    <Box className="post-info-block new-block">
                      <div className="tags-single">
                        <Text className="text-single">
                          Tags: Business, Tips{" "}
                        </Text>{" "}
                      </div>{" "}
                      <Box className="share">
                        <Text className="text-single"> Share </Text>{" "}
                        <Link to="#">
                          <TiSocialFacebook />
                        </Link>{" "}
                        <Link to="#">
                          <TiSocialTwitter />
                        </Link>{" "}
                        <Link to="#">
                          <TiSocialPinterest />
                        </Link>{" "}
                        <Link to="#">
                          <TiSocialLinkedin />
                        </Link>{" "}
                      </Box>{" "}
                    </Box>{" "}
                    <Box className="post-comment-block block-design">
                      <Box className="author-avatar image-author">
                        <Image
                          src={authorImage}
                          alt="cryptik comments author"
                        />
                      </Box>{" "}
                      <Box className="comment-body">
                        <Box className="comment-body-inner">
                          <Heading as="h3" className="about-section">
                            <Text as="span"> About </Text> Carl James{" "}
                          </Heading>{" "}
                        </Box>{" "}
                        <Box className="about-content text-contnt">
                          <Text>
                            Finalmente vol sol justamente crematorio vil nos.Bem
                            com sido esse case alem aos.Amor tive fara de dado
                            esse em.{" "}
                          </Text>{" "}
                        </Box>{" "}
                      </Box>{" "}
                    </Box>{" "}
                    <Box className="post-comment-wrapper">
                      <Heading as="h2" className="block-title">
                        5 Comments{" "}
                      </Heading>{" "}
                      <List>
                        <ListItem>
                          <Box className="post-comment-block post-comment">
                            <Box className="author-avatar image-author">
                              <Image
                                src={authorImage}
                                alt="cryptik comments author"
                              />
                            </Box>{" "}
                            <Box className="comment-body">
                              <Box className="comment-body-inner">
                                <Box className="post-content-block content-text">
                                  <Box className="post-meta-block">
                                    <Text as="span"> Andy Bogard </Text>{" "}
                                    <Text as="span" className="Boxider">
                                      {" "}
                                    </Text>{" "}
                                    <Text as="span">
                                      {" "}
                                      Posted January 02, 2020{" "}
                                    </Text>{" "}
                                    <Text as="span" className="Boxider">
                                      {" "}
                                    </Text>{" "}
                                    <Text as="span"> 07: 00 pm </Text>{" "}
                                  </Box>{" "}
                                  {/*
                                            <Text className="post-entry"> {post.body} </Text>{" "}
                                            <Link to="/blog-single" className="readmore-btn">
                                              see more <IoIosArrowRoundForward />
                                            </Link>{" "}
                                          */}{" "}
                                </Box>{" "}
                              </Box>{" "}
                              <Box className="comment-content single-blog-para">
                                <Text>
                                  Finalmente vol sol justamente crematorio vil
                                  nos.Bem com sido esse case alem aos.Amor tive
                                  fara de dado esse em.{" "}
                                </Text>{" "}
                                <div className="replay">
                                  <Link to="#">
                                    {" "}
                                    Replay <IoIosArrowRoundForward />
                                  </Link>{" "}
                                </div>{" "}
                              </Box>{" "}
                            </Box>{" "}
                          </Box>{" "}
                          <List>
                            <ListItem>
                              <Box className="post-comment-block post-comment">
                                <Box className="author-avatar image-author">
                                  <Image
                                    src={authorImage}
                                    alt="cryptik comments author"
                                  />
                                </Box>{" "}
                                <Box className="comment-body">
                                  <Box className="comment-body-inner">
                                    <Box className="post-content-block content-text">
                                      <Box className="post-meta-block">
                                        <Text as="span"> Andy Bogard </Text>{" "}
                                        <Text as="span" className="Boxider">
                                          {" "}
                                        </Text>{" "}
                                        <Text as="span">
                                          {" "}
                                          Posted January 02, 2020{" "}
                                        </Text>{" "}
                                        <Text as="span" className="Boxider">
                                          {" "}
                                        </Text>{" "}
                                        <Text as="span"> 07: 00 pm </Text>{" "}
                                      </Box>{" "}
                                      {/*
                                            <Text className="post-entry"> {post.body} </Text>{" "}
                                            <Link to="/blog-single" className="readmore-btn">
                                              see more <IoIosArrowRoundForward />
                                            </Link>{" "}
                                          */}{" "}
                                    </Box>{" "}
                                  </Box>{" "}
                                  <Box className="comment-content single-blog-para">
                                    <Text>
                                      Finalmente vol sol justamente crematorio
                                      vil nos.Bem com sido esse case alem
                                      aos.Amor tive fara de dado esse em.{" "}
                                    </Text>{" "}
                                    <div className="replay">
                                      <Link to="#">
                                        {" "}
                                        Replay <IoIosArrowRoundForward />
                                      </Link>{" "}
                                    </div>{" "}
                                  </Box>{" "}
                                </Box>{" "}
                              </Box>{" "}
                            </ListItem>{" "}
                            <ListItem>
                              <Box className="post-comment-block post-comment">
                                <Box className="author-avatar image-author">
                                  <Image
                                    src={authorImage}
                                    alt="cryptik comments author"
                                  />
                                </Box>{" "}
                                <Box className="comment-body">
                                  <Box className="comment-body-inner">
                                    <Box className="post-content-block content-text">
                                      <Box className="post-meta-block">
                                        <Text as="span"> Andy Bogard </Text>{" "}
                                        <Text as="span" className="Boxider">
                                          {" "}
                                        </Text>{" "}
                                        <Text as="span">
                                          {" "}
                                          Posted January 02, 2020{" "}
                                        </Text>{" "}
                                        <Text as="span" className="Boxider">
                                          {" "}
                                        </Text>{" "}
                                        <Text as="span"> 07: 00 pm </Text>{" "}
                                      </Box>{" "}
                                      {/*
                                            <Text className="post-entry"> {post.body} </Text>{" "}
                                            <Link to="/blog-single" className="readmore-btn">
                                              see more <IoIosArrowRoundForward />
                                            </Link>{" "}
                                          */}{" "}
                                    </Box>{" "}
                                  </Box>{" "}
                                  <Box className="comment-content single-blog-para">
                                    <Text>
                                      Finalmente vol sol justamente crematorio
                                      vil nos.Bem com sido esse case alem
                                      aos.Amor tive fara de dado esse em.{" "}
                                    </Text>{" "}
                                    <div className="replay">
                                      <Link to="#">
                                        {" "}
                                        Replay <IoIosArrowRoundForward />
                                      </Link>{" "}
                                    </div>{" "}
                                  </Box>{" "}
                                </Box>{" "}
                              </Box>{" "}
                            </ListItem>{" "}
                          </List>{" "}
                        </ListItem>{" "}
                        <ListItem>
                          <Box className="post-comment-block post-comment">
                            <Box className="author-avatar image-author">
                              <Image
                                src={authorImage}
                                alt="cryptik comments author"
                              />
                            </Box>{" "}
                            <Box className="comment-body">
                              <Box className="comment-body-inner">
                                <Box className="post-content-block content-text">
                                  <Box className="post-meta-block">
                                    <Text as="span"> Andy Bogard </Text>{" "}
                                    <Text as="span" className="Boxider">
                                      {" "}
                                    </Text>{" "}
                                    <Text as="span">
                                      {" "}
                                      Posted January 02, 2020{" "}
                                    </Text>{" "}
                                    <Text as="span" className="Boxider">
                                      {" "}
                                    </Text>{" "}
                                    <Text as="span"> 07: 00 pm </Text>{" "}
                                  </Box>{" "}
                                  {/*
                                        <Text className="post-entry"> {post.body} </Text>{" "}
                                        <Link to="/blog-single" className="readmore-btn">
                                          see more <IoIosArrowRoundForward />
                                        </Link>{" "}
                                      */}{" "}
                                </Box>{" "}
                              </Box>{" "}
                              <Box className="comment-content single-blog-para">
                                <Text>
                                  Finalmente vol sol justamente crematorio vil
                                  nos.Bem com sido esse case alem aos.Amor tive
                                  fara de dado esse em.{" "}
                                </Text>{" "}
                                <div className="replay">
                                  <Link to="#">
                                    {" "}
                                    Replay <IoIosArrowRoundForward />
                                  </Link>{" "}
                                </div>{" "}
                              </Box>{" "}
                            </Box>{" "}
                          </Box>{" "}
                        </ListItem>{" "}
                      </List>{" "}
                    </Box>{" "}
                    <Box className="comment-box-wrapper">
                      <Heading as="h2" className="block-title">
                        Add Comment{" "}
                      </Heading>{" "}
                      <Text>
                        Your email address will not be published.Required fields
                        are marked *{" "}
                      </Text>{" "}
                      <form>
                        <Box className="form-inline">
                          <input type="text" placeholder="Name*" />
                          <input type="email" placeholder="Email*" />
                        </Box>{" "}
                        <Box className="form-group">
                          <input type="text" placeholder="Website(Optional)" />
                        </Box>{" "}
                        <textarea
                          rows="10"
                          cols="50"
                          placeholder="Comment"
                        ></textarea>{" "}
                        <Button> Post Comment </Button>{" "}
                      </form>{" "}
                    </Box>{" "}
                  </Box>{" "}
                </Col>{" "}
              </Row>{" "}
            </Col>{" "}
            <Col className="sm-12 lg-4">
              <Sidebar />
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </Box>{" "}
    </BlogPageWrapper>
  )
}

export default BlogSinglePage
