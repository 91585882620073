import styled from "styled-components"
import Icon2 from "assets/images/blog/icon2.svg"

const BlogPageWrapper = styled.div `
  // new
  .about-content p {
    text-align: left;
    font: normal normal normal 17px/25px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
.text-contnt p{
  margin-bottom:0px !important;
}
  .post-comment {
    background: white;
    border-radius: 12px;
    padding: 41px 38px 30px 20px !important;
    margin-bottom: 10px;
    color: black;
  }
  .image-author {
    align-self: center;
    min-width: 100px !important;

    img {
      border-radius: 0% !important;
      width: 100px !important;
      height: 100px !important;
      max-width: 100px !important;
    }
  }
  .block-design {
    border-bottom: none !important;
    padding: 38px 94px 38px 24px !important;
    background: #00acf1 !important;
    border-radius: 12px;
  }
  .about-section {
    border-bottom: 1px solid white;
    width: 200px;
    margin-bottom: 10px !important;
    text-align: left;
    font: normal normal normal 21px/28px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif !important;
    span {
      margin-bottom: 0px;
      text-align: left;
      font: normal normal 300 12px/18px Poppins !important;
      letter-spacing: 0px;
      color: #ffffff !important;
      opacity: 1;
      font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif !important;
    }
  }
  .text-block {
    padding: 25px 0px !important;
  }

  .tags-single {
    margin-bottom: 10px;
  }
  .new-block {
    display: block;
  }
  .text-single {
    text-align: left;
    font: normal normal 300 16px/25px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 0.8;
    margin-bottom: 0px;
    margin-right: 10px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif !important;
  }

  .post-content-block {
    border-radius: 0 0 3px 3px;
    // border: 1px solid rgba(190, 171, 223, 0.55);
    padding: 25px 35px;
    transform: translateY(-11px);
    position: relative;
    z-index: 1;
  }

  .newblock {
    margin-bottom: 0px;
  }
  .post-entry {
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
  }

  .post-meta-block {
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
    a {
      margin-right: 10px;
      -webkit-transition: 450ms all;
      transition: 450ms all;
      &:hover {
        color: #ffebec;
      }
    }
    a,
    span {
      color: #fff;
      font-size: 14px;
      font-weight: 300;
    }
    span + span {
      margin-left: 10px;
    }
    .divider {
      font-size: 11px;
    }
  }

  // end
  .blog-Container {
    background: #210e54;
    margin-bottom: 132px;
  }

  .single-blog-header {
    padding: 0px 185px;
  }
  .Boxider {
    border-left: 2px solid #00acf1;
  }
  .first-cate {
    margin: 0px;
    margin-top: 75px;
  }

  .margin-head {
    margin: 0px;
  }

  .sidebar-design {
    background: #210e54;
    border: 1px solid #00acf1;
    margin-top: 125px;
    padding-bottom: 100px;
    border-radius: 12px;
  }

  .blog-Text {
    text-align: center;
    font: normal normal 300 28px/42px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 43px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif !important;
  }

  .banner__square {
    position: absolute;
    margin-left: -280px;
    top: 170px;
  }

  .banner__circle2 {
    position: absolute;
    top: 242px;
    margin-left: -148px;
  }

  .banner__circle1 {
    position: absolute;
    top: 60px;
    right: -300px;
  }

  @media (min-width: 1200px) {
    .blog-banner {
      width: 1303px;
      max-width: 1300px;
      margin-left: -95px;
      margin-top: -20px;
    }
  }
  .blog-banner {
    position: relative;

    padding: 0px;
  }

  .banner__thumb {
    // position: absolute;
    // right: 61%;
    // top: -27%;
    width: auto;
    height: auto;
  }
  @media(max-width:600px){
    
  }

  .single-post-wrapper {
    padding: 0px 0 180px 0;
  }
  .single-post-block {
    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 29px;
      letter-spacing: 0.2px;
      font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif !important;
    }
    p + p {
      margin-top: 30px;
    }
    .entry-three {
      color: #ffffff;
      font-weight: 500;
    }
    &:hover {
      .post-thumb-block {
        img {
          // transform: scale3d(1.1, 1.1, 1);
        }
      }
    }
  }
  .post-thumb-block {
    overflow: hidden;
    // margin-bottom: 40px;
    position: relative;
    border-radius: 6px;
    .post-meta {
      padding: 15px 23px;
      border-radius: 6px;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: ${props => props.theme.white};
      width: 100px;
      background: rgba(255, 255, 255, 0.15);
      position: absolute;
      top: 10px;
      right: 10px;
      em {
        font-weight: 600;
        font-size: 28px;
        line-height: 35px;
        display: block;
        font-style: normal;
      }
    }
    img {
      width: 100%;
      // transition: all 0.6s ease-in;
      border-radius: 6px;
    }
  }

  .post-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 1.5px;
    margin-bottom: 25px;
    display: block;
  }

  blockquote {
    padding-left: 90px;
    padding-bottom: 30px;
    background: transparent;
    overflow: hidden;
    font-size: 32px;
    line-height: 45px;
    border-radius: 6px;
    margin: 60px 0 40px 0;
    position: relative;
    box-sizing: border-box;
    position: relative;
    color: #beabdf;
    &:before {
      content: "";
      width: 60px;
      height: 3px;
      background: #beabdf;
      position: absolute;
      top: 20px;
      left: 0;
    }
    &:after {
      content: url(${Icon2});
      position: absolute;
      right: 0;
      bottom: -22px;
      transform: rotate(180deg);
      z-index: -1;
      opacity: 1;

      transition: all 1s ease-in-out;
    }

    span {
      display: block;
      font-size: 85%;
      margin-top: 10px;
      opacity: 0.8;
    }
  }

  .post-info-block {
    // display: flex;
    display: block;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 80px;
    // border-bottom: 1px solid rgba(190, 171, 223, 0.55);
    padding-bottom: 30px;
  }
  .tags {
    a {
      font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif !important;
      color: #beabdf;
      margin-left: 10px;
      display: inline-block;
      padding: 6px 18px;
      border-radius: 3px;
      font: 400 13px/26px "Poppins", sans-serif;
      transition: all 0.3s linear;
      &:hover {
        color: #c838e7;
      }
    }
  }
  .share {
    display: flex;
    align-items: center;
    a {
      line-height: 100%;
      &:hover svg {
        color: #c838e7;
      }
    }
    svg {
      font-size: 30px;
      color: #beabdf;
      margin-right: 10px;
      line-height: 100%;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear;
      border: 1px solid;
      border-radius: 50%;
      padding: 3px;
    }
  }
  .block-title {
    font-size: 36px;
    color: ${props => props.theme.white};
    font-weight: 500;
    padding-bottom: 20px;
    text-align: left;
    font: normal normal normal 37px/56px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif !important;
  }

  .comment-body-inner,
  .replay a {
    display: flex;
    align-items: center;
  }

  .post-comment-wrapper {
    padding-top: 40px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      ul {
        margin-left: 70px;
      }
    }
  }
  .post-comment-block {
    display: flex;
    padding: 50px 0 40px;
    border-bottom: 1px solid #65528f;
  }
  .author-avatar {
    min-width: 95px;
    padding-right: 15px;
    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
  }
  .comment-body-inner {
    justify-content: space-between;
    h3 {
      font-size: 20px;
      color: ${props => props.theme.white};
      font-weight: 400;
      padding-bottom: 5px;
      margin: 0;
      span {
        display: block;
        font-size: 15px;
        font-style: italic;
        color: #beabdf;
      }
    }
  }
  .replay {
    a {
      color: #000;

      width: 85px;
      transition: all 0.3s linear;
      svg {
        font-size: 30px;
        transition: all 0.3s linear;
      }
      &:hover {
        color: #b537e6;
        svg {
          transform: translateX(5px);
        }
      }
    }
  }

  .comment-content {
    padding-top: 10px;
    p {
      line-height: 25px;
      margin-bottom: 0;
    }
  }

  .comment-box-wrapper {
    padding-top: 120px;
    .block-title {
      padding-bottom: 0px;
    margin-bottom: 0px;
}
    }
    p {
      margin-bottom: 40px;
    }
    input,
    textarea {
      color: #beabdf;
      background: #fff;
      margin-bottom: 35px;
      padding: 18px 15px;
      border-radius: 5px;
      border: none;
      width: 100%;
      font-size: 16px;
      transition: all 0.3s linear;
      &::placeholder {
        color: #989ca2;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #989ca2;
      }

      &::-ms-input-placeholder {
        color: #989ca2;
      }
      &:focus {
        border-color: #c838e7;
      }
    }

    textarea {
      resize: none;
    }

    .form-inline {
      display: flex;
      justify-content: space-between;

      input {
        flex-basis: 50%;

        &:first-child {
          margin-right: 25px;
        }
      }
    }
    button {
      width: 220px;
      border-radius: 5px;
      border: none;
      padding: 10px;
      cursor: pointer;
      color: ${props => props.theme.white};
      transition: all 0.3s ease-in;
      background: rgb(200, 56, 231);
      background: linear-gradient(
        270deg,
        rgba(31, 42, 213, 1) 0%,
        rgba(200, 56, 231, 1) 100%
      );
      &:hover {
        background: rgb(200, 56, 231);
        background: linear-gradient(
          270deg,
          rgba(200, 56, 231, 1) 0%,
          rgba(31, 42, 213, 1) 100%
        );
      }
    }
  }

  @media only screen and (max-width: 912px) {
    .single-post-block {
      padding-bottom: 120px;
    }
  }
  @media only screen and (max-width: 480px) {
    .post-info-block {
      display: block;
      .tags {
        margin-bottom: 40px;
      }
    }
    .post-comment-wrapper {
      li {
        ul {
          margin-left: 0;
        }
      }
    }
    .comment-box-wrapper {
      .form-inline {
        display: block;
        input {
          &:first-child {
            margin-right: 0;
          }
        }
      }
    }
    blockquote {
      padding-left: 0;
      &:before {
        content: none;
      }
    }
    .tags {
      a {
        padding: 6px 14px;
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .post-title {
      font-size: 20px;
      line-height: 30px;
    }
    blockquote {
      font-size: 24px;
      line-height: 38px;
    }
    .block-title {
      font-size: 28px;
    }
  }

  .comment-body {
    padding-left: 35px;
  }

  .single-blog-para p {
    text-align: left;
    font: normal normal 300 17px/25px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: -15px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif !important;
  }
  .content-text {
    padding: 0px 0px 0px 0px;
    .post-meta-block span {
      font: normal normal 300 16px/25px Poppins;
      letter-spacing: 0px;
      color: #000000;
      font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif !important;
    }
  }

  @media (max-width: 600px) {
    .banner__thumb {
      width: 100%;
      height: auto;
      margin-top: 73px;
    }
    .banner__circle1 {
      display: none;
    }
    .blog-Container {
      margin-bottom: 50px;
  }
  .sidebar-design {
    padding: 20px;
    margin-top: 83px;
    padding-bottom: 80px;
  }
  .single-blog-header {
    padding: 0px; 
  } 
  }
`
export default BlogPageWrapper